import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Table } from "react-bootstrap";
import { allUser, getRewardData } from "../../../services/api_function";

export const RewardUserDetail = () => {
  const [apiData, setApiData] = useState([]);
  const [targetBusiness, setTargetBusiness] = useState(""); // Updated variable name
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [targetBusiness]); // Fetch data whenever targetBusiness changes

  const fetchData = () => {
    
    getRewardData(targetBusiness).then((res) => {
      setApiData(res); // Assuming the response data needs to be set to apiData
    });
  };

  const handleFilterChange = (e) => {
    setTargetBusiness(e.target.value); // Update targetBusiness on dropdown change
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div className="m-2">
            <select
              name="planFilter"
              value={targetBusiness} // Updated value
              onChange={handleFilterChange} // Added event handler
              className="form-select"
            >
              <option value="">--Select Business Range--</option>
              <option value="10000">10000</option>
              <option value="50000">50000</option>
              <option value="100000">100000</option>
            </select>
          </div>
        </div>

        <Col lg={12}>
          <Card>
            <Card.Header style={{ background: "black", border: "1px solid white" }}>
              <Card.Title style={{ color: "white", margin: "auto" }}>Cto Achiever </Card.Title>
            </Card.Header>
            <Card.Body
              style={{
                background: "black",
                border: "1px solid white",
                borderRadius: "3px",
              }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "0.5px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th><strong>NO.</strong></th>
                    <th><strong>UserID</strong></th>
                   
                    <th><strong>Target Business ($)</strong></th>
                    <th><strong>PowerLeg</strong></th>
                    <th><strong>Weak Leg</strong></th>
                    <th><strong>Rank Number</strong></th>
                    <th><strong>Date&Time</strong></th>
                   
                  </tr>
                </thead>
                <tbody>
                  {apiData?.map((user, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{user?.user?.userId}</td>
                     
                     
                      <td className="text-center">{Number(user?.targetbusiness ?? 0).toFixed(2)}</td>
                      <td className="text-center">{Number(user?.powerleg ?? 0).toFixed(2)}</td>
                      <td className="text-center">{Number(user?.weakleg ?? 0).toFixed(2)}</td>
                      <td className="text-center">{Number(user?.rankno ?? 0)}</td>
                      <td>{formatTimestamp(user.createdAt)}</td>
                     
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default RewardUserDetail;
